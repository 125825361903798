/* --- Navbar / Topnav --- */

.nav-item{
    padding: 0 15px;
}

.nav-item .nav-link{
    font-weight: 700;
    font-size: 17px;
    text-transform: uppercase;
    color: #3c4858;
}

.dropdown-menu .dropdown-item{
    font-weight: 600;
    font-size: 15px;
    text-transform: uppercase;
    color: #3c4858;
}

.nav-item .dropdown-menu{
    border: none !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 0px 4px 0px;
}

.nav-item .nav-link:hover{
    color: #0d6efd !important;
}

.active{
    color: #0d6efd !important;
}

.dropdown-toggle::after {
    display:none;
}

@media all and (min-width: 992px) {
	.navbar .nav-item .dropdown-menu { 
        display: none; 
    }
	.navbar .nav-item:hover .dropdown-menu { 
        display: block; 
    }
	.navbar .nav-item .dropdown-menu { 
        margin-top:0; 
    }
}

.scrolled{
    background-color: #FFFFFF;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    transition: 0.8s;
}

.top-nav .container-fluid{
    padding-left: 0px !important;
    padding-right: 0px !important;
}

@media (max-width: 991.98px) {
    /* Change the background color of the dropdown menu in mobile view */
    .navbar .dropdown-menu {
      background-color: #FFFFFF !important; /* Replace with your desired color */
    }
  
    /* Change the background color of the collapsed navbar menu */
    .navbar .navbar-collapse {
      background-color: #FFFFFF !important; /* Replace with your desired color */
    }
  }

/* --- Footer --- */    

.foot-subscribe #emailsubscribe{
    background-color: #3c4858 !important;
    border: 1px solid #8492a6 !important;
    color: #f8f9fc !important;
}    